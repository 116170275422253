import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';
import { cssClass } from 'yet-another-react-lightbox';

interface ToastOptions {
    title?: string;
    type?: SweetAlertIcon;
    position?: SweetAlertPosition;
    showConfirmButton?: boolean;
    timerDuration?: number;
    target?: string | HTMLElement;
}

export const showToast = (options: ToastOptions) => {
    // Provide default values if not specified in options
    const { title, type = 'success', position = 'top-end', target, showConfirmButton = false, timerDuration = 3000 } = options;

    const toast: any = Swal.mixin({
        toast: true,
        position,
        showConfirmButton,
        timer: timerDuration,
        customClass: { container: 'toast' },
        target,
    });

    const alert = Swal.getPopup();
    // console.log('called2', alert);

    if (alert === null) {
        toast.fire({
            icon: type,
            title,
            padding: '10px 20px',
        });
    }
};
